
/* Dependencies */
import { defineComponent } from 'vue';
import {Form, FormActions} from 'vee-validate';

// Components
import Select from '../../Atoms/Select/Select.vue';
import Input from '../../Atoms/Input/Input.vue';
import CheckboxList from '../../Molecules/CheckboxList/CheckboxList.vue';

// Models
import {
  formSchema,
  contactOptions,
  marketingOptions,
} from './PartnerForm.model';
import MarketingPreference from "@/components/Molecules/MarketingPreference/MarketingPreference.vue";
import { getLocaleForDomain } from '@/services/locales.service';

/**
 * PartnerForm component
 */
export default defineComponent({
  components: {
    MarketingPreference,
    Form,
    Select,
    Input,
    CheckboxList,
  },
  props: {
    partnerId: String,
    default: () => 'No Partner Id Given'
  },
  data() {
    return {
      formSchema,
      contactOptions,
      marketingOptions,
    };
  },

  methods: {

    // Method to update marketing preferences
    updateMarketingPreference(e: any, setFieldValue: FormActions<Record<string, string>>['setFieldValue']) {
      const state = e.target.checked ? "1" : "0"

      marketingOptions.forEach((option) => setFieldValue(option.name, state))
    },

    // Method to handle checkbox value change
    handleCheckboxValueChange(e: any, setFieldValue: FormActions<Record<string, string>>['setFieldValue']) {
      const state = e.target.checked ? "1" : "0"

      setFieldValue(e.target.name, state)
    },

    // Method to generate Dropdown options
    generateDropdownOptions(baseKey : string, isCountry : boolean) {

      // Create an array to store the options
      const options = [];

      // Start with the first option
      let i = 1; 

      // Loop through the options until no valid option is found
      while (true) {
        const key = `${baseKey}_${i}`;
        const value = this.$t(key) ?? "";

        // Stop the loop if the translation is empty or the value matches the key (i.e., no valid translation)
        // or if the loop has run for more than 1000 iterations
        if (value === "" || value === key || i > 1000) {
          break; // Exit the loop when no valid option is found
        }

        // Add the option to the array
        if (isCountry===true) {
          options.push({ key: value.substring(3), value: value.substring(0,2) });
        }
        else {
          options.push({ key: value, value: value });
        }  

        i++; // Increment to the next option
      }

      return options; // Return the array of valid options
    }

  },

  computed: {

    // Method to get the submit URL
    submitUrl() {
      return process.env.VUE_APP_SF_FORM_URL;
    },

    // Method to get the return URL
    returnUrl() {
      return process.env.VUE_APP_BASE_URL + getLocaleForDomain() + process.env.VUE_APP_SF_FORM_RETURN_URL;
    },

    // Method to get business type options
    businessTypeOptions() {
      return this.generateDropdownOptions('page.fields.business_type_option', false);
    },

    // Method to get turnover range options
    turnoverRangeOptions() {
      return this.generateDropdownOptions('page.fields.turnover_range', false);
    },

    // Method to get title options
    titleOptions() {
      return this.generateDropdownOptions('page.fields.title_option', false);
    },  

    // Method to get country name options
    countryNameOptions() {
      return this.generateDropdownOptions('page.fields.country_name', true);
    }    

  }
});
