/**
 * Get the supported locales.
 *
 * @returns {string[]}
 */
export const getSupportedLocales = (): string[] => {
  return ['en-GB', 'de-DE', 'pl-PL', 'sv-SE', 'no-NO']
}

/**
 * Get the primary domain for the locale.
 *
 * @param {string} locale
 *
 * @returns {string}
 */
export const getDomainForLocale = (locale: string) => {
  const domains: Record<string, string> = {
    "en-GB": process.env.VUE_APP_DOMAIN_EN_GB,
    "de-DE": process.env.VUE_APP_DOMAIN_DE_DE,
    "pl-PL": process.env.VUE_APP_DOMAIN_PL_PL,
    "sv-SE": process.env.VUE_APP_DOMAIN_SV_SE,
    "no-NO": process.env.VUE_APP_DOMAIN_NO_NO,
  }

  return domains[locale] ? domains[locale]: '#'
}

/**
 * Get the mapping of domains to locale.
 *
 * @returns {<Record<string, string>>}
 */
export const getLocaleDomainMapping = (): Record<string, string> => {
  return {
    [process.env.VUE_APP_DOMAIN_EN_GB]: "en-GB",
    [process.env.VUE_APP_DOMAIN_DE_DE]: "de-DE",
    [process.env.VUE_APP_DOMAIN_PL_PL]: "pl-PL",
    [process.env.VUE_APP_DOMAIN_SV_SE]: "sv-SE",
    [process.env.VUE_APP_DOMAIN_NO_NO]: "no-NO",
  }
}

/**
 * Get the users preferred language from their browser.
 *
 * @returns {string}
 */
export const getBrowserPreferredLanguage = (): string => {
  return navigator.language;
}

/**
 * Get the locale which should be used for a domain.
 *
 * @returns {string}
 */
export const getLocaleForDomain = (): string => {

  // Get the current page address and then strip off the query string and any specific page.
  let host = window.location.href;
  const url = new URL(host);
  url.search = '';
  host = url.toString().replace("thank-you", "");
  
  const mapping = getLocaleDomainMapping();

  return mapping[host] ? mapping[host] : 'en-GB';
}

/**
 * Get the translations for a specific locale.
 *
 * @returns {Record<string, string>}
 */
export const getTranslations = (): Record<string, Record<string, string>> => {
  let translations: Record<string, Record<string, string>> = {};
  const supported = getSupportedLocales();

  supported.forEach((l) => {
    translations[l] = require('../locales/' + l)
  })

  return translations
}