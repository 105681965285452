import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full max-w-4xl mx-auto py-8 mt-8" }
const _hoisted_2 = { class: "text-5xl text-primary text-center font-light" }
const _hoisted_3 = { class: "w-full rounded-md px-4 md:px-12 py-8 bg-grey mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnerForm = _resolveComponent("PartnerForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('page.heading')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_PartnerForm, { partnerId: _ctx.partnerId }, null, 8, ["partnerId"])
    ])
  ]))
}