/* Dependencies */
import { markRaw } from 'vue';
import * as yup from 'yup';
import {CheckboxValue} from "@/models/CheckboxValue.model";

/**
 * Generics
 */
export const contactOptions: CheckboxValue[] = [
  { id: "00N1p00000JATdl", name: "00N1p00000JATdl", value: 'Email Consent' },
  { id: "00N1p00000JATdo", name: "00N1p00000JATdo", value: 'Phone Consent' },
  { id: "00N1p00000JATdp", name: "00N1p00000JATdp", value: 'SMS Consent' },
];

export const marketingOptions: CheckboxValue[] = [
  { id: "00N4H00000ERk4x", name: "00N4H00000ERk4x", value: 'Email' },
  { id: "00N4H00000ERk52", name: "00N4H00000ERk52", value: 'Phone' },
  { id: "00N4H00000ERk57", name: "00N4H00000ERk57", value: 'SMS' },
];

/**
 * Form Schema
 */
export const formSchema = markRaw(
  yup.object().shape({
    salutation: yup
      .string()
      .required('Field is required'),
    first_name: yup
      .string()
      .max(40, 'Maximum of 40 characters allowed')
      .required('Field is required'),
    last_name: yup
      .string()
      .max(80, 'Maximum of 80 characters allowed')
      .required('Field is required'),
    "company": yup
      .string()
      .max(150, 'Maximum of 150 characters allowed')
      .required('Field is required'),
    "00N24000005gAv2": yup
      .string()
      .required('Field is required'),
    phone: yup.string().required('Field is required'),
    email: yup.string().required('Field is required').email('Invalid email address'),
    url: yup
      .string()
      .max(255, 'Maximum of 40 characters allowed'),
    country_code: yup
      .string()
      .required('Field is required'),
    "00N1p00000JALUl": yup
      .string()
      .optional(),
      acknowledgement: yup.bool().oneOf([true],'Field is required'),     
  })
);