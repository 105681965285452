/* Dependencies */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/en-GB',
  },
  {
    path: '/:lang',
    name: 'Partner Form',
    component: () => import('../views/PartnerFormView.vue'),
  },
  {
    path: '/:lang/thank-you',
    name: 'Thank You',
    component: () => import('../views/ThankYou.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;