import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createBlock(_component_Checkbox, {
    option: { id: 'marketingPreference', name: 'marketingPreference' },
    onHandleChange: _ctx.handleChange
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('page.fields.marketing_text_prefix')) + " ", 1),
      _createElementVNode("a", {
        class: "underline text-primary font-bold",
        rel: "noreferrer",
        target: "_blank",
        href: _ctx.$t('page.fields.privacy_policy_url')
      }, _toDisplayString(_ctx.$t('page.fields.marketing_text_suffix')), 9, _hoisted_1)
    ]),
    _: 1
  }, 8, ["onHandleChange"]))
}