
/* Dependencies */
import { Options, Vue } from 'vue-class-component';
import LanguageBanner from '@/components/Organisms/LanguageBanner/LanguageBanner.vue';
import { getBrowserPreferredLanguage, getSupportedLocales } from '@/services/locales.service';
import Header from '@/components/Organisms/Header/Header.vue';

/**
 * App Component
 */
@Options({
  components: {
    Header,
    LanguageBanner,
  },
    computed: {
    partnerId() {
      return this.$route.query.partner_id;
    },
    isOnPreferredLocale() {
      const preferredLocale = getBrowserPreferredLanguage();
      const supportedLocales = getSupportedLocales();

      if (!supportedLocales.includes(preferredLocale)) {
        return true;
      }

      return preferredLocale === this.$i18n.locale;
    }
  },  
})
export default class App extends Vue {}
