import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-end mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguageOverlay = _resolveComponent("LanguageOverlay")!
  const _component_Flag = _resolveComponent("Flag")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showingLanguageSelector)
      ? (_openBlock(), _createBlock(_component_LanguageOverlay, {
          key: 0,
          onOnCloseOverlay: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showingLanguageSelector = false))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleToggleSelector && _ctx.handleToggleSelector(...args)))
      }, [
        _createVNode(_component_Flag, {
          circle: false,
          locale: _ctx.currentLocale,
          onOnFlagClicked: _ctx.handleToggleSelector
        }, null, 8, ["locale", "onOnFlagClicked"])
      ])
    ])
  ]))
}