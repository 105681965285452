import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'relative overflow-hidden flex items-center w-4 h-4': _ctx.circle}),
    style: _normalizeStyle([_ctx.flagContainerStyles])
  }, [
    _createElementVNode("img", {
      style: _normalizeStyle([_ctx.flagImgStyles]),
      class: _normalizeClass({'absolute top-0 left-o w-full h-full object-center object-cover': _ctx.circle}),
      src: _ctx.getFlagUrl,
      alt: _ctx.$t(`countries.${_ctx.locale}`),
      title: _ctx.$t(`countries.${_ctx.locale}`)
    }, null, 14, _hoisted_1)
  ], 6))
}