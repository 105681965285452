/* Dependencies */
import 'array-flat-polyfill';
import { createApp } from 'vue';
import router from './router';
import { createI18n } from 'vue-i18n'
import { getLocaleForDomain, getTranslations } from '@/services/locales.service';

// CSS
import './index.scss';

// Components
import App from './App.vue';

const locale = getLocaleForDomain();
const messages = getTranslations();

const i18n = createI18n({
  locale,
  messages,
  silentTranslationWarn: true,
})

// Create the initial application.
createApp(App).use(i18n).use(router).mixin({
    computed: {
      currentLocale() { 
        return this.$i18n.locale 
      }
    }
  }).mount('#app');
