
/* Dependencies */
import { defineComponent } from 'vue';
import LanguageSelector from '@/components/Organisms/LanguageSelector/LanguageSelector.vue';

/**
 * Header component
 */
export default defineComponent({
  components: { LanguageSelector },
  props: {
    partnerId: {
      type: String,
      required: false,
    },
  },
});
