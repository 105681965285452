import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguageBanner = _resolveComponent("LanguageBanner")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isOnPreferredLocale)
      ? (_openBlock(), _createBlock(_component_LanguageBanner, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Header, { "partner-id": _ctx.partnerId }, null, 8, ["partner-id"]),
    _createVNode(_component_router_view)
  ], 64))
}