
/* Dependencies */
import { defineComponent } from 'vue';
import Flag from '@/components/Molecules/Flag/Flag.vue';
import LanguageOverlay from '@/components/Molecules/LanguageOverlay/LanguageOverlay.vue';

/**
 * Header component
 */
export default defineComponent({
  components: { LanguageOverlay, Flag },
  props: {
  },
  data() {
    return {
      showingLanguageSelector: false
    }
  },
  methods: {
    handleToggleSelector() {
      this.showingLanguageSelector = !this.showingLanguageSelector;
    }
  }
});
