
/* Dependencies */
import { defineComponent } from 'vue';

// Components
import PartnerForm from '../components/Organisms/PartnerForm/PartnerForm.vue';
import Header from '../components/Organisms/Header/Header.vue';
import LanguageBanner from '../components/Organisms/LanguageBanner/LanguageBanner.vue';

/**
 * Partner Forms View
 */
export default defineComponent({
  components: {
    LanguageBanner,
    PartnerForm,
    Header,
  },
  computed: {
    partnerId() {
      return this.$route.query.partner_id;
    },
  },
});
