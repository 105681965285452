
/* Dependencies */
import { defineComponent, computed } from 'vue';
import { getSupportedLocales, getDomainForLocale } from '@/services/locales.service';
import Flag from '@/components/Molecules/Flag/Flag.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: { Flag },
  setup() {
    const route = useRoute();
    const query = computed(() => route.query);
    return {
      query
    };
  },
  props: {

  },

  mounted() {
    document.body.classList.add("overflow-hidden")
  },

  unmounted() {
     document.body.classList.remove("overflow-hidden")
  },

  data() {    
    return {
      locales: getSupportedLocales()
    }
  },

  methods: {
    getDomain(locale: string, query: any) {

      // convert the LocationQuery object to a URLSearchParams object
      const queryParams = new URLSearchParams(query).toString();

      if (queryParams) {
        return getDomainForLocale(locale) + '?' + queryParams;
      }

      return getDomainForLocale(locale);
    },
    handleClose() {
      this.$emit('onCloseOverlay')
    }
  }
});
