import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "text-error"
}
const _hoisted_3 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "text-primary text-base font-medium"
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1),
    _createVNode(_component_Field, {
      name: _ctx.name,
      id: _ctx.id,
      as: "select",
      class: _normalizeClass(["block w-full h-9 mt-1 border-form-border border border-solid rounded-sm px-2", [
      Object.keys(_ctx.errors).includes(_ctx.name)
        ? 'border-2 border-solid border-error'
        : '',
    ]]),
      "aria-describedby": `${_ctx.id}_help-text`,
      required: _ctx.required ? _ctx.required : false
    }, {
      default: _withCtx(() => [
        _createElementVNode("option", _hoisted_3, "-- " + _toDisplayString(_ctx.$t('page.select')) + " --", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: `${_ctx.name}_${index}`,
            value: option.value
          }, _toDisplayString(option.key), 9, _hoisted_4))
        }), 128))
      ]),
      _: 1
    }, 8, ["name", "id", "aria-describedby", "class", "required"]),
    (Object.keys(_ctx.errors).includes(_ctx.name))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translateErrors(_ctx.errors)), 1),
          _createVNode(_component_ErrorMessage, {
            name: _ctx.name,
            id: `${_ctx.id}_help-text`
          }, null, 8, ["name", "id"])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}