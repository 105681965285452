
/* Dependencies */
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    locale: {
      type: String,
      required: true
    },
    circle: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    getFlagUrl() {
      return `/img/flags/${this.locale}.svg`
    },
    flagContainerStyles() {
      if (this.circle === true) {
          return {borderRadius: '70%', width: '40px', height: '40px'}
      }

      return {}
    },
    flagImgStyles() {
      if (this.circle === false) {
        return {width: '40px', border: '1px solid rgba(12, 32, 116, 0.2)'}
      }

      return {}
    }
  }
});
