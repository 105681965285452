
/* Dependencies */
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';

// Components
import ErrorMessage from '../ErrorMessage/ErrorMessage.vue';

/**
 * Input component
 */
export default defineComponent({
  props: {
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => []
    },
    required: {
      type: Boolean,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
  },
  components: {
    Field,
    ErrorMessage,
  },
  methods: {
    translateErrors(errors : { [key: string]: string }) {
      // replace with translated content
      if (errors[this.name] === "Field is required") {
        errors[this.name] = this.$t('page.validation_messages.field_required');
      }
      if (errors[this.name] === "Invalid email address") {
        errors[this.name] = this.$t('page.validation_messages.invalid_email');
      }
      if (errors[this.name] === "Maximum of 40 characters allowed") {
        errors[this.name] = this.$t('page.validation_messages.max_40_characters');
      }
      if (errors[this.name] === "Maximum of 80 characters allowed") {
        errors[this.name] = this.$t('page.validation_messages.max_80_characters');
      }
      if (errors[this.name] === "Maximum of 150 characters allowed") {
        errors[this.name] = this.$t('page.validation_messages.max_150_characters');
      }
      return errors;
    }
  }
});
